<template>
  <div>
    <h2 class="mb-6">{{$t('Inventory')}}</h2>
    <head-component :noActions="true" :statusFilters="merchants" @changeFilter="changeFilter" @filterValue="filterValue = $event"></head-component>
    <div class="lg:w-1/4 md:w-1/2 w-full ml-auto">
      <div class="inventory-card text-center w-full">
        <p class="font-medium text-darkblue">{{ $t('Total Current Inventory') }}</p>
        <p class="text-darkblue mt-1">{{ totalInventoryLevel }}</p>
      </div>
    </div>
    <div class="float-left flex gap-1 items-center whitespace-nowrap lg:mt-4 clearfix">
      <print-label-button class="mr-4 float-left whitespace-nowrap" :selected="selected"></print-label-button>
      <div class="cursor-pointer vs-con-loading__container" id="download-btn-loading">
        <div class="available-action flex px-4 py-2" @click="downloadInventory()">
          <feather-icon icon="DownloadIcon" svgClasses="w-4 h-4" class="self-center"/>
          <span class="text-sm ml-2">{{ $t('Download') }}</span>
        </div>
      </div>      
    </div>
    <shipblu-table
     :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :data="products"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Product Image')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Product Name')}}</shipblu-th>
        <shipblu-th>{{$t('SKU')}}</shipblu-th>
        <shipblu-th>{{$t('Size')}}</shipblu-th>
        <shipblu-th>{{$t('Color')}}</shipblu-th>
        <shipblu-th>{{$t('Dimensions')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Reserved Inventory')}}</shipblu-th>
        <shipblu-th>{{$t('Current Inventory')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].image">
            <img :src=" data[indextr].image " class="w-10 h-10"/>
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].merchantName">
            {{ data[indextr].group.merchant.name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].name">
            {{ data[indextr].name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].sku">
            {{ data[indextr].sku }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].size">
            {{ data[indextr].size }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].color">
            <div class="color-container h-6 w-3/4">
              <div class="w-full h-full" :style="`background-color:${data[indextr].color}`"></div>
              <p class="mt-2" v-if="!data[indextr].color.includes('#')">{{data[indextr].color}}</p>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].dimensions">
            {{ data[indextr].d_length }} x {{ data[indextr].d_width }} x {{ data[indextr].d_height }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].locations">
            <p class="link" @click="openLocationModal(data[indextr])">{{ data[indextr].locations.length }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].reserved_inventory">
            {{ data[indextr].reserved_inventory }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].inventory">
            <span>{{data[indextr].current_inventory_level}}</span>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    
    <shipblu-prompt
      @close="closeModal"
      class="shipment-modal"
      :active.sync="locationModal"
      :title="$t('Location')"
      :buttons-hidden="true">
      <div class="mb-4 shadow-drop rounded-lg flex justify-between items-center p-4" v-for="location in product.locations" :key="location.index">
        <span>{{location.location}}</span>
        <div>
          <span class="text-grey text-lg font-medium"> {{$t('Qty')}}{{': '}}</span>
          <span class="text-blue-100"> {{`[${location.stored_quantity}]`}}</span>
        </div>
      </div>
    </shipblu-prompt>
    <print-product-barcode :selected="selected"/>
  </div>
</template>

<script>
import common  from '@/assets/utils/common'
import {sendFulfillmentRequest} from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPrompt from '../../layouts/components/ShipBluPrompt.vue'
import PrintLabelButton from '../headOfFleet/components/PrintLabelButton.vue'
import PrintProductBarcode from '../merchant/components/PrintProductBarcode.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import HeadComponent from '../merchant/components/Header.vue'
import i18nData from '../../i18n/i18nData.js'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      locationModal: false,
      products: [],
      product: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchInProgress: false,
      searchedValue: ' ',
      totalInventoryLevel: '',
      tableLoader: false,
      totalReservedInventory: '',
      merchants: [],
      filters: '',
      filterValue: ''
    }
  },
  watch : {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadProducts()
    }
  },
  methods: {
    downloadInventory () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#download-btn-loading',
        scale: 0.45
      })
      sendFulfillmentRequest(true, false, this, 'api/v1/fc/products/export/', 'post', this.filters ? {merchant_id: Number(this.filters)} : {}, true, 
        (response) => {
          this.$vs.loading.close('#download-btn-loading > .con-vs-loading')
          this.$vs.notify({
            color: 'success',
            title: i18nData[this.$i18n.locale]['Success'],
            text: response.data.message,
            position: 'top-center'
          })
        }
      )
    },
    getstyle (status) {
      return `background: ${common.getOrderStatusColor(status)}`
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    changeFilter () {
      this.filters = ''
      this.filters = this.filterValue
      if (this.filterValue === 'all') {
        this.filters = ''
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          page: 1,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadProducts()
    },
    closeModal (modalName) {
      this.productModal = false
      this.locationModal = false
      if (modalName) {
        this.loadProducts()
      }
    },
    loadProducts () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}&merchant=${this.filters}`
      sendFulfillmentRequest(true, false, this, `api/v1/fc/products/${query}`, 'get', null, true,
        (response) => {
          this.products = response.data.results
          this.totalRows = response.data.count
          this.totalInventoryLevel = response.data.total_inventory_level
          this.totalReservedInventory = response.data.total_reserved_inventory
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    openLocationModal (data) {
      this.product = data
      this.locationModal = true
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadProductCatalogSearch)
    },
    loadProductCatalogSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadProducts()
    },
    loadMerchants () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/merchants/?limit=100', 'get', null, true, 
        (response) => {
          this.merchants = response.data.results
          this.merchants.unshift({
            name: 'All',
            value: 'all'
          })
        })
    },
    printLabel (product) {
      this.printProduct = product
      window.print()
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPrompt,
    PrintLabelButton,
    PrintProductBarcode,
    ShipbluPagination,
    HeadComponent
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : ''
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadProducts()
    this.loadMerchants()
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
.color-container {
  border: 1px solid #000;
  border-radius: 7px;
  padding: 2px;
  margin: auto !important;
  div {
    border-radius: 5px;
  }
}
.inventory-card {
  background: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 25px 0px rgba(0,0,0,.1);
  -webkit-transition: all .3s ease-in-out;
}
</style>