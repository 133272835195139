<template>
  <div id="order-label-print">
    <div v-for="product in selected" :key="product.index" class="mt-5">
      <svg class="barcode" jsbarcode-displayvalue="true" jsbarcode-format="code128" :jsbarcode-value="product.sku" jsbarcode-height="92" jsbarcode-width="2"></svg>
    </div>
  </div>
</template>

<script>
export default {
  props: ['selected'],
  data () {
    return {}
  }
}
</script>

<style lang="scss">
@media print{
  @page {
    size: letter;
  }
  #fc_frame {
    display: none;
  }
  .shipment-page,
  .dropdown-menu{
    *{
      visibility: hidden;
      color: black;
    }
    #order-label-print,
    #order-label-print *{
      visibility: visible;
    }
    #order-label-print{
      display: block;
    }
    #content-area {
      margin: 0 !important;
    }
    #order-label-print {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
  }
}
</style>